import BaseService from "@core/services/baseService";

export default class FaqService extends BaseService{
    createFaq(...args){
        return this.axiosIns.post(this.formConfig.faqEndpoint,...args)
    }
    updateFaq(...args){
        return this.axiosIns.post(this.formConfig.faqEndpoint+"/edit/"+args[0].get('id'),...args)
    }
    getFaq(...args){
        return this.axiosIns.get(this.formConfig.faqEndpoint+"/"+args[0].id,...args)
    }
    getAllFaqs(...args){
        return this.axiosIns.get(this.formConfig.faqEndpoint,...args)
    }
}