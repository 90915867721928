<template>
    <section id="faq-tabs">
        <b-tabs vertical content-class="col-12 col-md-8 col-lg-9" pills nav-wrapper-class="faq-navigation col-md-4 col-lg-3 col-12" nav-class="nav-left">
            <b-tab v-for="(category,index) in categories" :key="category.id" :active="!index">
                <template #title>
                    <span class="font-weight-bold">{{ category.name }}</span>
                </template>
                <question-answer :options="category.faqs"/>
            </b-tab>
        </b-tabs>
    </section>
</template>
<script>
    import {BTabs,BTab} from 'bootstrap-vue'
    import faqService from "@core/services/faq/useFaq";
    import QuestionAnswer from './QuestionAnswer.vue'
    import {$themeConfig} from "@themeConfig";
    export default{
        components: {
            BTabs,
            BTab,
            QuestionAnswer
        },
        data(){
            return {
                categories: []
            }
        },
        methods: {
            getFaqs(){
                faqService.getAllFaqs().then(response => {
                    this.categories = response.data;
                }).catch(error => {
                    if(error.response.status == 403){
                        window.location = $themeConfig.app.suspendedAccountRedirect;
                    }
                });
            }
        },
        mounted(){
            this.getFaqs();
        }
    }
</script>
<style lang="scss" scoped>
    .per-page-selector{width:90px;}
</style>
<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
</style>