<template>
    <div v-if="options">
        <app-collapse id="faq-qna" accordion type="margin" class="mt-2">
            <app-collapse-item v-for="(data,index) in options" :key="index" :title="data.question">{{ data.answer }}</app-collapse-item>
        </app-collapse>
    </div>
</template>
<script>
    import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
    import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
    export default {
        components: {
            AppCollapse,
            AppCollapseItem
        },
        props: {
            options: {
                type: Array,
                default: () => {}
            }
        }
    }
</script>